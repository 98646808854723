<template>
  <div>
    <v-form>
      <v-container fluid>
        <v-row class="justify-center">
            <v-col class="offset-md-1 pl-0" cols="2">
                <h2>Reset Password</h2>
            </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pb-2 pt-0 mt-0">
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pb-2 pt-0 mt-0">
            <v-text-field
            v-model="password"
            label="New Password"
            @click:append="showPasswordHint = !showPasswordHint"
            :type="showPasswordHint ? 'text' : 'password'"
            :append-icon="showPasswordHint ? 'mdi-eye' : 'mdi-eye-off'">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="2" class="pb-2 pt-0 mt-0">
            <v-text-field
            type="password"
            v-model="confirmedPassword"
            label="Re-enter New Password"
            :rules="[rules.emailMatch]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="offset-md-1" cols="2">
            <v-btn color="primary" :disabled="!canSubmit" @click="handleSubmit">
                Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      email: '',
      password: '',
      confirmedPassword: '',
      token: this.$route.query.token,
      showPasswordHint: false,
      rules: {
        emailMatch: null,
      },
      isSubmitting: false,
    };
  },
  computed: {
    canSubmit() {
      return this.password !== '' && this.confirmedPassword !== '' && this.email && !this.isSubmitting;
    },
  },
  methods: {
    ...mapActions('account', ['resetPassword']),
    async handleSubmit() {
      this.isSubmitting = true;
      if (this.password !== this.confirmedPassword) {
        this.rules.emailMatch = () => ('The password you entered doesn\'t match');
      } else {
        this.rules.emailMatch = null;
        await this.resetPassword({ email: this.email, password: this.password, token: this.token });
      }
      this.isSubmitting = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (_.isEmpty(to.query)) {
        next('/');
      } else {
        next();
      }
    });
  },
  created() {

  },
};
</script>
